import { define, html } from "hybrids";
import { isPast, intervalToDuration, differenceInDays } from "date-fns";

import Plyr from "plyr";
import mux from "mux-embed";
import styles from "plyr/dist/plyr.css";
import svgIcons from "plyr/dist/plyr.svg?raw";
import User from "~/stores/User";

export interface VBroadcastPlayer {
  src: string;
  poster: string;
  startAt: Date | null;
  owner: User;
  availableUntil: Date | null;
  disabled: boolean;
  countdown: Duration | null;
  provider: "plyr" | "iframely";
  plyr: Plyr;
  live: boolean;
  muted: boolean;
  background: boolean;
  muxDataApiKey: string;
  videoId: string;
  videoTitle: string;
  viewerUserId: string;
  iframelyKey: string;
  iframely: Promise<HTMLElement | null>;
  render: () => HTMLElement;
}

const srcMap = new WeakMap();
const formatDate = (v: string) => (v ? new Date(v) : null);

function unmute(host: VBroadcastPlayer) {
  host.muted = false;
  (host.render().querySelector("video") as HTMLVideoElement).muted = false;
}

export default define<VBroadcastPlayer>({
  tag: "v-broadcast-player",
  src: "",
  poster: "",
  startAt: { set: (_, value) => formatDate(value) },
  owner: { set: (_, User) => User },
  availableUntil: { set: (_, value) => formatDate(value) },
  videoId: "",
  videoTitle: "",
  viewerUserId: "",
  disabled: ({ availableUntil }) =>
    !!(availableUntil && isPast(availableUntil)),
  countdown: {
    get: ({ startAt }) => {
      if (!startAt || isPast(startAt)) return null;
      const now = new Date();

      const countdown = intervalToDuration({
        start: now,
        end: startAt,
      });

      countdown.days = differenceInDays(startAt, now);

      return countdown;
    },
    connect(host, key, invalidate) {
      const intervalId = setInterval(() => {
        if (host[key]) {
          invalidate();
        } else {
          clearInterval(intervalId);
        }
      }, 1000);

      return () => {
        clearInterval(intervalId);
      };
    },
  },
  provider: ({ src }) => (src.match(/\.m3u8$/) ? "plyr" : "iframely"),
  plyr: (host, lastPlayer) => {
    const { src, poster, countdown, background, disabled } = host;

    if (lastPlayer) {
      if (srcMap.get(lastPlayer) === src && countdown) {
        if (lastPlayer.poster !== poster) lastPlayer.poster = poster;
        return lastPlayer;
      } else {
        lastPlayer.destroy();
      }
    }

    const enabled = !countdown && !disabled && !!src;
    const wrapper = document.createElement("div");

    const video = document.createElement("video");
    video.controls = false;
    video.crossOrigin = "";
    video.playsInline = true;
    video.poster = poster;
    video.src = enabled ? src : "";

    if (background) {
      video.muted = true;
      video.loop = true;
    }

    if (enabled && src) {
      import("hls.js").then(({ default: Hls }) => {
        if (Hls.isSupported()) {
          const hls = new Hls({ liveDurationInfinity: true });
          hls.loadSource(src);
          hls.attachMedia(video);

          if (typeof mux !== "undefined") {
            mux.monitor(video, {
              debug: false,
              hlsjs: hls,
              Hls: Hls,
              data: {
                env_key: host.muxDataApiKey,
                video_id: host.videoId,
                video_title: host.videoTitle,
                viewer_user_id: host.viewerUserId,
              },
            });
          }
        }
      });
    }

    video.addEventListener("loadedmetadata", () => {
      host.live = host.live || video.duration === Infinity;

      video.play().catch((e) => {
        host.muted = true;
        video.muted = true;
        video.play();
      });
    });

    wrapper.appendChild(video);

    const player = new Plyr(video, {
      ratio: "16:9",
      volume: 0.8,
      loadSprite: false,
      clickToPlay: enabled,
      fullscreen: { enabled, fallback: true, iosNative: true },
      keyboard: { focused: enabled, global: false },
      invertTime: false,
      toggleInvert: false,
      controls:
        enabled && !background
          ? [
              "play-large",
              "play",
              "progress",
              "current-time",
              "mute",
              "volume",
              "pip",
              "settings",
              "airplay",
              "fullscreen",
            ]
          : [],
      settings: ["quality"],
    });

    player.poster = poster;
    srcMap.set(player, src);

    return player;
  },
  live: false,
  muted: false,
  background: false,
  muxDataApiKey: "",
  iframelyKey: "",
  iframely: async ({ src, iframelyKey }) => {
    // @ts-ignore
    const { iframely } = await import("@iframely/embed.js");
    const el = document.createElement("div");

    iframely.extendOptions({ key: iframelyKey });
    iframely.load(el, src);

    return el.querySelector("iframe");
  },

  render: (host) => {
    const { startAt, countdown, provider, poster, disabled } = host;

    return html`
      ${(disabled || countdown || (startAt && isPast(startAt) && !host.src)) &&
      html`
        <div id="poster" style="background-image: url(${poster})">
          ${countdown &&
          html`
            <div id="countdown">
              <v-ui-text class="number" weight="semibold">
                ${countdown.days}
              </v-ui-text>
              <v-ui-text>Days</v-ui-text>
              <v-ui-text class="number" weight="semibold">
                ${countdown.hours}
              </v-ui-text>
              <v-ui-text>Hours</v-ui-text>
              <v-ui-text class="number" weight="semibold">
                ${countdown.minutes}
              </v-ui-text>
              <v-ui-text>Minutes</v-ui-text>
              <v-ui-text class="number seconds" weight="semibold">
                ${countdown.seconds}
              </v-ui-text>
              <v-ui-text>Seconds</v-ui-text>
            </div>
          `}
          ${startAt &&
          isPast(startAt) &&
          !host.src &&
          html`
            <div id="disabled">
              <v-ui-text>
                Waiting for ${host.owner.name} to start streaming...
              </v-ui-text>
            </div>
          `}
          ${disabled &&
          html`
            <div id="disabled">
              <v-ui-text>
                The viewing period for this video has expired, as set by the
                event organizer.
              </v-ui-text>
            </div>
          `}
        </div>
      `}
      ${!disabled &&
      !countdown &&
      ((provider === "plyr" &&
        html`
          <div id="plyr" class="${{ live: host.live }}">
            ${host.plyr.elements.container}
            ${host.live &&
            html`<v-ui-text id="live-icon" weight="semibold" variant="caption">
              • Live
            </v-ui-text>`}
            ${host.muted &&
            !host.background &&
            html`<v-ui-button
              type="outline"
              id="muted-button"
              onclick="${unmute}"
            >
              <svg aria-hidden="true" focusable="false">
                <use xlink:href="#plyr-muted"></use>
              </svg>
              <v-ui-text>Click to unmute</v-ui-text>
            </v-ui-button>`}
            <div id="icons" innerHTML="${svgIcons}"></div>
          </div>
        `) ||
        (provider === "iframely" && html.resolve(host.iframely)))}
    `.style(styles).css`
      :host { 
        display: block;
        background: black;
      }

      #plyr {
        position: relative;
      }

      #plyr.live .plyr__progress {
        display: none;
      }

      .plyr {
        --plyr-color-main: var(--v-ui-color-secondary);
      }

      #live-icon {
        position: absolute;
        top: 10px;
        left: 10px;
        background: rgba(102, 36, 131, 0.7);
        padding: 2px 6px;
        border-radius: 6px;
        pointer-events: none;
        opacity: 0.8;
      }

      #muted-button {
        position: absolute;
        top: 6%;
        left: 50%;
        transform: translateX(-50%);
        color: white;
      }

      #muted-button svg {
        display: block;
        width: 18px;
        height: 18px;
        margin-left: 8px;
        fill: currentcolor;
      }

      #muted-button v-ui-text {
        margin: 0 8px;
      }

      #poster, iframe {
        border: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
      }

      #poster {
        box-sizing: border-box;
        padding: 20px;
        display: grid;
        place-content: center;
      }

      #poster > div {
        background: rgba(18, 18, 18, 0.8);
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        border-radius: 16px;
      }

      #countdown {
        display: grid;
        grid: repeat(2, min-content) / repeat(4, 60px);
        grid-gap: 8px 20px;
        grid-auto-flow: column;
        align-items: center;
        text-align: center;
        padding: 16px 24px;
      }

      #countdown v-ui-text {
        font-size: 16px;
      }

      #countdown v-ui-text.number {
        position: relative;
        font-size: 44px;
        line-height: 44px;
      }

      #countdown v-ui-text.number:after {
        content: ":";
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -16px;
        font-size: 40px;
        line-height: 56px;
        font-weight: normal;
      }

      #countdown v-ui-text.number.seconds:after {
        display: none;
      }

      #disabled {
        max-width: 550px;
        padding: 24px;
      }

      #disabled v-ui-text {
        text-align: center;
        font-size: 18px;
      }

      #icons {
        display: none;
      }

      @media (min-width: 480px) {
        #live-icon {
          top: 16px;
          left: 16px;
        }
      }

      @media (min-width: 931px) {
        #countdown {
          grid-template-columns: repeat(4, 96px);
        }

        #countdown v-ui-text.number {
          font-size: 56px;
          line-height: 56px;
        }

        #disabled v-ui-text {
          font-size: 30px;
        }
      }
    `;
  },
});
